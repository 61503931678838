<template>
  <v-app>
    <v-app-bar color="primary" dark app v-if="state != 0">
      <v-app-bar-nav-icon>
        <v-icon>grading</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title>{{ title }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn color="teal accent-4" fab small>
        <v-icon>close</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-main>
      <v-card elevation="4" outlined class="ma-4 pb-2">
        <v-img
          position="top center"
          height="280"
          src="../assets/mnl.png"
          v-if="state == 0"
        ></v-img>
        <v-card-title v-if="state == 0"> {{ title }} </v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
          <v-form ref="userInfo" lazy-validation v-if="state == 0">
            
            <v-text-field
              v-model="userInfo.name"
              label="姓名"
              prepend-inner-icon="person"
              type="text"
              :rules="userRules.name"
            />
            <v-select
              v-model="userInfo.sex"
              :items="[
                { text: '男', value: '1' },
                { text: '女', value: '2' },
              ]"
              label="性别"
              prepend-inner-icon="wc"
              :rules="userRules.sex"
            />
            <v-text-field
              v-model="userInfo.age"
              label="年龄"
              prepend-inner-icon="cake"
              type="number"
              :rules="userRules.age"
            />
            <v-select
              v-model="userInfo.blood_type"
              :items="[
                { text: 'A', value: 'A' },
                { text: 'B', value: 'B' },
                { text: 'AB', value: 'AB' },
                { text: 'O', value: 'O' },
                { text: '不知道', value: '不知道' },
              ]"
              label="血型"
              prepend-inner-icon="bloodtype"
              :rules="userRules.blood_type"
            />
            <v-text-field
              v-model="userInfo.phone"
              label="手机号"
              prepend-inner-icon="phone"
              type="number"
              :rules="userRules.phone"
            />
          </v-form>

          <v-form v-if="state == 1 && questions.length > 0">
            <div @click.stop="disableProgress">
              <v-progress-linear
                :value="processValue"
                color="blue-grey"
                rounded
                dark
                striped
                height="25"
                class="mb-4"
              >
                <template>
                  <strong
                    >{{ currentQuestion + 1 }}/{{ questions.length }}</strong
                  >
                </template>
              </v-progress-linear>
            </div>

            <v-card-title>{{
              questions[currentQuestion].question
            }}</v-card-title>
            <v-radio-group v-model="answerList[currentQuestion]">
              <v-radio
                v-for="(item, index) in questions[currentQuestion].options"
                :key="index"
                :label="item.answer"
                :value="index"
                class="mb-4"
              ></v-radio>
            </v-radio-group>
          </v-form>

          <v-form v-if="state == 2" ref="result">
            <div ref="screenshot">
              <div style="margin: auto; width: 80%">
                <canvas id="pie-chart"></canvas>
              </div>

              <div class="text-center mt-2">
                <p>
                  <v-chip dark small class="mr-2" color="rgb(255, 99, 132)">
                    红色 </v-chip
                  >快乐帮助者，热情开朗，但是情绪化
                </p>

                <p>
                  <v-chip class="mr-2" dark small color="rgb(54, 162, 235)">
                    蓝色 </v-chip
                  >最佳执行者，心思缜密，但容易抑郁
                </p>
                <p>
                  <v-chip class="mr-2" dark small color="rgb(255, 205, 86)">
                    黄色 </v-chip
                  >天生领导者，自信高效，但过于强势
                </p>
                <p>
                  <v-chip class="mr-2" dark small color="rgb(75, 192, 192)">
                    绿色 </v-chip
                  >和平促进者，温柔亲切，但易被欺压
                </p>
              </div>
              <div class="text-center mt-8 teal--text">四种成人血型分析</div>
              <v-divider class="mx-4 ma-2"></v-divider>
              <div class="d-flex justify-space-around">
                <div class="text-center">
                  <div class="font-weight-bold">A型底色</div>
                  <p>蓝色&绿色</p>
                </div>
                <div class="text-center">
                  <div class="font-weight-bold">B型底色</div>
                  <p>红色</p>
                </div>
                <div class="text-center">
                  <div class="font-weight-bold">AB型底色</div>
                  <p>红蓝/蓝红</p>
                </div>
                <div class="text-center">
                  <div class="font-weight-bold">O型底色</div>
                  <p>黄色</p>
                </div>
              </div>
            </div>
            
          </v-form>
        </v-card-text>
        <v-card-actions class="ml-4 mr-4">
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="prevQuestion"
            v-if="state == 1 && currentQuestion > 0"
            >上一题</v-btn
          >
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="retest"
            v-if="state == 2"
            >重新测试</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="startTest"
            v-if="state == 0"
            >开始测试</v-btn
          >
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="nextQuestion"
            :disabled="nextDisabled"
            v-if="state == 1 && currentQuestion < questions.length - 1"
            >下一题</v-btn
          >

          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="showDialog"
            v-if="state == 1 && currentQuestion == questions.length - 1"
            >提交</v-btn
          >
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="takeScreenshot"
            v-if="state == 2"
            >保存结果</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="isLoading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="showShot">
        <v-card>
          <v-card-title>            
            <v-icon color="pink darken-1">pan_tool_alt</v-icon>
            <span class="grey--text">长按保存结果</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="showShot = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <img :src="shotpic" style="width:100%"/>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showModal"  max-width="600px">
        <v-card>
          <v-card-title>
          <span>请输入验证码</span>
        </v-card-title>
        <v-card-text> 
          <img @click="changeCaptcha"  :src="imgcode" alt="图片验证码" v-if="imgcode">
          <v-text-field
            v-model="captcha"
            label="验证码"
            prepend-inner-icon="lock"
            type="number"
          />
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="primary"
            elevation="3"
            rounded
            @click="submit"
          >确认</v-btn>
        </v-card-actions> 
  
        </v-card>
      </v-dialog>

    </v-main>
  </v-app>
</template>
<script>

import html2canvas from "html2canvas";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { get_questions, check_user_info, submit_answers,captcha} from "@/api/test";

const audio = new Audio();
audio.src = require("@/assets/shot.mp3");
export default {
  name: "testQuestions",
  
  data() {
    return {
      nextDisabled: true,
      showShot: false,
      showModal: false,
      isLoading: false,
      state: 0,
      currentQuestion: 0,
      processValue: 4,
      answerList: [],
      title: "觅能量成人血型性格科学测试",
      questions: [],
      result: {
        红色: "3",
        蓝色: "2",
        黄色: "1",
        绿色: "0",
      },
      key:"",
      captcha:"",
      imgcode:"",
      shotpic:"",
      userInfo: {
        name: "",
        sex: "",
        age: "",
        blood_type: "",
        phone: "",
      },
      userRules: {
        name: [
          (v) => !!v || "姓名不能为空",
          (v) => (v && v.length <= 10) || "姓名不能超过10个字符",
        ],
        sex: [(v) => !!v || "性别不能为空"],
        age: [
          (v) => !!v || "年龄不能为空",
          (v) => (v && v >= 0 && v <= 150) || "年龄不合法",
        ],
        blood_type: [(v) => !!v || "血型不能为空"],
        phone: [
          (v) => !!v || "手机号不能为空",
          (v) => /^[1][0-9][0-9]{9}$/.test(v) || "手机号格式不正确",
        ],
      },
    };
  },
  created() {
    this.fetchData();
    //processValue值为currentQuestion+1/questions.length*100
    this.$watch("currentQuestion", (val) => {
      this.processValue = ((val + 1) / this.questions.length) * 100;
    });
    //监听answerList的变化，如果answerList[currentQuestion]不为空，则nextDisabled为false
    this.$watch("answerList", (val) => {
      console.log(val[this.currentQuestion] )
      if (val[this.currentQuestion] != undefined) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    });
    //监听currentQuestion的变化，如果answerList[currentQuestion]不为空，则nextDisabled为false
    this.$watch("currentQuestion", (val) => {
      if (this.answerList[val] != undefined) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    });
  },

  methods: {
    async fetchData() {
      let data = await get_questions();
      if (data) {
        this.questions = data.data;
      }
    },
    async startTest() {
      if (this.$refs.userInfo.validate()) {
        this.isLoading = true;
        let data = {};
        data = await check_user_info(this.userInfo).finally(() => {
          this.isLoading = false;
        });
        if (data) {
          if (data.data) {
            this.state = 1;
          } else {
            this.state = 1;
          }
        }
      }
    },
    nextQuestion() {      
      this.currentQuestion++;      
    },
    prevQuestion() {
      this.currentQuestion--;
    },
    async submit() {      
      
      this.isLoading = true;
      let data = {};
      let postData = this.userInfo;
      postData.answers = this.answerList;
      postData.key = this.key;
      postData.captcha = this.captcha;
      data = await submit_answers(postData).finally(() => {
        this.isLoading = false;
      });
      if(data==undefined){
        this.changeCaptcha();
      }
      if (data) {
        this.showModal = false;
        this.state = 2;
        let result = data.data;
        setTimeout(() => {
          this.drawPie(result);
        }, 100);
      }
    },
    takeScreenshot() {
      
      html2canvas(this.$refs.screenshot).then((canvas) => {
        this.shotpic  = canvas.toDataURL();
        this.showShot = true;
        
        // const link = document.createElement("a");
        // link.href = imgData;
        // link.download = "result.png";
        // link.click();
      });
      //播放shot.mp3
      audio.play();     
     

    },
    retest() {
      this.state = 0;
      this.currentQuestion = 0;
      this.captcha = "";
      this.answerList = [];
      this.userInfo = {
        name: "",
        sex: "",
        age: "",
        blood_type: "",
        phone: "",
      };
    },

    changeCaptcha() {
      captcha().then((res) => {
        console.log(res);
        this.key = res.data.key;
        this.imgcode  = res.data.img;
      });
      
    },
    showDialog() {
     
      //判断answerList是有空值
      if (this.answerList.includes(undefined)) {
        this.$toast.error("请完成所有题目再提交");
        return;
      }
      //判断answerList长度是否等于questions长度
      if (this.answerList.length != this.questions.length) {
        this.$toast.error("请完成所有题目再提交");
        return;
      }
      this.changeCaptcha();
      this.showModal = true;      
    },

    drawPie(result) {
      const ctx = document.getElementById("pie-chart").getContext("2d");
      new Chart(ctx, {
        type: "doughnut", // 图表类型
        data: {
          labels: ["红色", "蓝色", "黄色", "绿色"], // 饼图的标签
          datasets: [
            {
              label: "性格分布", // 数据集的标签
              data: result, // 每个标签的数据值
              backgroundColor: [
                "rgb(255, 99, 132)", // 饼图扇区的颜色
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
              ],
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "尊敬的" + this.userInfo.name + "，您的血型性格分布如下：",
            },
            legend: {
              display: false,
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                if (percentage == "0.00%") {
                  percentage = "";
                }
                return percentage;
              },
              color: "#fff",
            },
          },
        },
        plugins: [ChartDataLabels],
      });
    },
  },
};
</script>


