import request from "@/utils/request";

export  function get_questions() {
    return request({
      url: '/public/questions',
      method: 'get',
    })
  }

export function check_user_info(data) {
  return request({
    url: '/public/check_user_info',
    method: 'post',
    data:data
  });
}

export function submit_answers(data) {
  return request({
    url: '/public/submit_answers',
    method: 'post',
    data:data
  });
}


export  function captcha() {
  return request({
    url: '/public/captcha',
    method: 'get',
  })
}
