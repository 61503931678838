/*
 * @Author: zhang_fly 15620636416@163.com
 * @Date: 2023-07-17 14:45:58
 * @LastEditors: zhang_fly 15620636416@163.com
 * @LastEditTime: 2023-08-07 13:50:44
 * @FilePath: /h5/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/AdminView.vue'
import DeviceSwitchView from '../views/DeviceSwitchView.vue'
import OperationRecordsView from '../views/OperationRecordsView.vue'
import ShopOperationRecordsView from '../views/ShopOperationRecordsView.vue'
import ShopInfoView from '@/views/ShopInfoView.vue'
import LoginView from '../views/LoginView.vue'
import RechargeRecordsView from '../views/RechargeRecordsView.vue'
import StaffListView from '../views/StaffListView.vue'
import StaffDetailView from '../views/StaffDetailView.vue'
import TestQuestionsView from '../views/TestQuestionsView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {      
      title: '觅能量',     
    },
  },
  {
    path: '/operation_records',
    name: 'operation_records',
    component: OperationRecordsView,
    meta: {      
      title: '操作记录',     
    },
  },

  {
    path: '/shop_operation_records',
    name: 'shop_operation_records',
    component: ShopOperationRecordsView,
    meta: {      
      title: '操作记录',     
    },
  },
 
  {
    path: '/device_switch',
    name: 'device_switch',
    component: DeviceSwitchView,
    meta: {      
      title: 'DeviceSwitch',     
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: {      
      title: '管理',     
    },
  },
  {
    path: '/shop_info',
    name: 'shopinfo',
    component: ShopInfoView,
    meta: {      
      title: '店铺信息',     
    },
  },
  {
    path: '/staff',
    name: 'staff',
    component: StaffListView,
    meta: {      
      title: '员工',     
    },
  },
  {
    path: '/staff_detail',
    name: 'staff_detail',
    component: StaffDetailView,
    meta: {      
      title: '员工详情',     
    },
  },
  {
    path: '/recharge_records',
    name: 'recharge_records',
    component: RechargeRecordsView,
    meta: {      
      title: '充调记录',     
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {      
      title: '用户登录',     
    },
  },

  {
    path: '/test_questions',
    name: 'test_questions',
    component: TestQuestionsView,
    meta: {      
      title: '觅能量性格测试',     
    },
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
